













import { Observer } from 'mobx-vue'
import { Component, Inject, Vue } from 'vue-property-decorator'
import { MintViewModel } from '../viewmodels/mint-viewmodel'
import { walletStore } from '@/stores/wallet-store'

@Observer
@Component({
  components: {
    'mint-process-register': () => import('@/modules/mint/components/process/mint-process-register.vue'),
    'mint-process-opt-in': () => import('@/modules/mint/components/process/mint-process-opt-in.vue'),
    'mint-process-sale': () => import('@/modules/mint/components/process/mint-process-sale.vue'),
    'mint-process-lottery': () => import('@/modules/mint/components/process/mint-process-lottery.vue'),
    'mint-process-connect-wallet': () => import('@/modules/mint/components/process/mint-process-connect-wallet.vue'),
  },
})
export default class extends Vue {
  @Inject({}) vm!: MintViewModel
  walletStore = walletStore
}
